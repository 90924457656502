import React, { useState, useEffect, Fragment } from "react";

const ClientOnlyComponent = ({ children }) => {
  const [inBrowser, setInBrowser] = useState();
  useEffect(() => {
    setInBrowser(true);
  }, []);

  if (!inBrowser) {
    return null;
  }

  return Array.isArray(children) ? (
    children.map((child, index) => <Fragment key={index}>{child}</Fragment>)
  ) : (
    <Fragment key="unique">{children}</Fragment>
  );
};

export default ClientOnlyComponent;
