const isTailwindBreakpoint = (breakpoint) => {
    try {
        const breakpoints = {
            xs: 319,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            "2xl": 1536,
        };

        return window.innerWidth <= breakpoints[breakpoint];
    } catch (e) {}
};

export default isTailwindBreakpoint;
