import { Logo } from "datamynt-ui-library";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { HiOutlineMenu } from "react-icons/hi";

import useClickListener from "../../hooks/useClickListener";
import ExchangeRates from "./ExchangeRates";
import NavDropdownCustomers from "./NavDropdownCustomers";
import NavDropdownProduct from "./NavDropdownProduct";

const navStyles =
  "bg-[#141518] top-0 left-0 text-white p-6 md:px-8 sm:py-8 w-full z-50";

const Nav = ({ onCurrencyChangeShelling, onCurrencyChangeNaira }) => {
  const router = useRouter();
  const dropdownActive = useClickListener("dm__nav_dropdown_menu", false);

  return (
    <>
      <nav
        className={`${navStyles} bg-[#141518] hidden md:flex justify-between`}
      >
        <Link href="/">
          <a className="flex items-center">
            <Logo type={"DmDefaultFullColor"} />
          </a>
        </Link>

        <ul className="flex items-center text-md md:space-x-2 lg:space-x-8 ">
          <li>
            <Link href="/">
              <a
                className={
                  /contactus/.test(router.pathname)
                    ? "text-primary"
                    : "text-white hover:text-primary ml-2"
                }
              >
                Home
              </a>
            </Link>
          </li>
          <li>
            <Link href="/aboutus">
              <a
                className={
                  /aboutus/.test(router.pathname)
                    ? "text-primary"
                    : "text-white hover:text-primary"
                }
              >
                About us
              </a>
            </Link>
          </li>
          <li>
            <Link href="/agents">
              <a
                className={
                  /agents/.test(router.pathname)
                    ? "text-primary"
                    : "text-white hover:text-primary hidden lg:block "
                }
              >
                Become An Agent
              </a>
            </Link>
          </li>
          <li>
            <NavDropdownCustomers />
          </li>
          <li>
            <NavDropdownProduct />
          </li>
          <li>
            <ExchangeRates
              onCurrencyChangeNaira={onCurrencyChangeNaira}
              onCurrencyChangeShelling={onCurrencyChangeShelling}
            />
          </li>
        </ul>

        <Link href="/login">
          <a
            key="1"
            target="_blank"
            rel="noreferrer"
            className="hover:shadow-blue py-2 px-8 rounded-full text-white border-white border"
          >
            Login
          </a>
        </Link>
      </nav>
      <nav
        className={`${navStyles} flex justify-between items-center md:hidden`}
      >
        <Link href="/">
          <a>
            <Logo type={"DmDefaultFullColor"} />
          </a>
        </Link>
        <div id="dm__nav_dropdown_menu">
          <HiOutlineMenu size={20} />
          <ul
            className={`${
              dropdownActive ? "flex flex-col" : "hidden"
            } bg-white text-black  rounded-b-2xl space-y-4 absolute top-12 right-8 py-4 px-6 z-50`}
          >
            <li>
              <Image src={"/Vectorlogo.svg"} height={50} width={50} alt={""} />
            </li>
            <li className={"static"}>
              <ExchangeRates
                onCurrencyChangeNaira={onCurrencyChangeNaira}
                onCurrencyChangeShelling={onCurrencyChangeShelling}
              />
            </li>
            <li>
              <Link href="/">
                <a>Home</a>
              </Link>
            </li>
            <li className={"static"}>
              <NavDropdownCustomers />
            </li>
            <li className={"static"}>
              <NavDropdownProduct />
            </li>
            <li>
              <Link href="/aboutus">
                <a
                  className={`${
                    /aboutus/.test(router.pathname) ? "text-primary" : ""
                  } hover:text-byob`}
                >
                  About Us
                </a>
              </Link>
            </li>
            <li>
              <Link href="/contactus">
                <a
                  className={`${
                    /contactus/.test(router.pathname) ? "text-primary" : ""
                  } hover:text-byob`}
                >
                  Contact us
                </a>
              </Link>
            </li>
            <li>
              <Link href="/contactus">
                <a
                  className="hover:text-byob"
                  key="1"
                  target="_blank"
                  rel="noreferrer"
                >
                  BYOB
                </a>
              </Link>
            </li>
            <li>
              <Link href="/agents">
                <a
                  className="hover:text-byob"
                  key="1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Become An Agent
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://angel.co/company/cryptopayments_/jobs">
                <a target="_blank" rel="noreferrer" className="hover:text-byob">
                  Careers
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://zfrmz.com/bukVz5q3SzM18DSnC4PM">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="bg-black rounded-2xl p-2 text-white px-5 mr-3"
                >
                  SIGN UP
                </a>
              </Link>
              <Link href="/login">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="bg-gray-50 rounded-2xl p-2 text-black px-5"
                >
                  SIGN IN
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Nav;
