import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const currency = [
  { name: "NIGERIAN NAIRA- NGN", value: "NGN" },
  { name: "KENYAN SHILLING - KES", value: "KES" }
];

export default function ExchangeRates(props) {
  const [selected, setSelected] = useState(currency[0]);

  return (
    <>
      <Listbox value={selected} onChange={setSelected}>
        <Listbox.Button className="relative cursor-default rounded-lg bg-transparent py-2  pr-10 text-left sm:text-sm">
          <span>{selected.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="lg:absolute mt-1 rounded-md bg-white text-black py-1 text-base shadow-lg  sm:text-sm">
            {currency.map((person, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-4 pr-2 ${
                    active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                  }`
                }
                value={person}
              >
                {({ selected }) => (
                  <>
                    <div
                      onClick={() => {
                        if (person.value === "NGN") {
                          props.onCurrencyChangeShelling("NGN");
                        }
                        if (person.value === "KES") {
                          props.onCurrencyChangeShelling("KSH");
                        }
                      }}
                    >
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person.name}
                      </span>
                    </div>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </>
  );
}
