const AppStoreButton = ({ store, type, grow }) => (
  <a
    target="_blank"
    href={
      store === "apple"
        ? "https://apps.apple.com/us/app/datamynt-point-of-sale-pos/id1572840222"
        : "https://play.google.com/store/apps/details?id=com.datamynt&hl=en&gl=US"
    }
    rel="noreferrer"
    className={`px-6 py-4 flex flex-grow ${
      grow ? "flex flex-1" : "min-w-[10.5rem] md:max-w-[12rem]"
    } rounded-full ${
      type === "dark" ? "bg-[#292B36]" : "bg-white"
    } flex items-center justify-center space-x-2`}
  >
    <div>
      {store === "apple" ? (
        <svg
          width="25"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.017 12.556c-.01-1.599.715-2.804 2.178-3.692-.818-1.172-2.056-1.816-3.687-1.94-1.545-.122-3.235.9-3.853.9-.654 0-2.15-.858-3.326-.858C6.9 7.003 4.32 8.902 4.32 12.764c0 1.142.209 2.32.626 3.534.558 1.599 2.569 5.515 4.667 5.452 1.096-.026 1.872-.778 3.3-.778 1.385 0 2.102.778 3.325.778 2.117-.03 3.935-3.59 4.465-5.194-2.838-1.338-2.686-3.919-2.686-4Zm-2.463-7.149c1.188-1.41 1.08-2.695 1.045-3.157-1.05.06-2.264.715-2.955 1.519-.762.862-1.21 1.929-1.114 3.131 1.135.087 2.17-.497 3.024-1.493Z"
            fill={type !== "dark" ? "#292B36" : "white"}
          />
        </svg>
      ) : (
        <svg
          width="25"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.109 1.814 14.292 12 4.11 22.186a.997.997 0 0 1-.61-.92V2.734a1 1 0 0 1 .609-.92Zm10.89 10.893 2.302 2.302-10.937 6.333 8.635-8.635Zm3.199-3.198 2.807 1.626a1 1 0 0 1 0 1.73l-2.808 1.626L15.706 12l2.492-2.491ZM6.364 2.658 17.302 8.99l-2.303 2.303-8.635-8.635Z"
            fill={type !== "dark" ? "#292B36" : "white"}
          />
        </svg>
      )}
    </div>

    <p
      className={`text-xs lg:text-base font-semibold ${
        type !== "dark" ? "text-[#292B36]" : "text-white"
      }`}
    >
      {store === "apple" ? "App Store" : "Google Play"}
    </p>
  </a>
);

export default AppStoreButton;
