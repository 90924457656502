import { Logo } from "datamynt-ui-library";
import Image from "next/image";
import Link from "next/link";
import React, { useContext } from "react";
import {
  FaLinkedinIn,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import AppStoreButton from "./AppStoreButton";
import isTailwindBreakpoint from "../../helpers/twBreakpoints";
import ClientOnlyComponent from "../ClientOnlyComponent";

const Footer = () => {
  return (
    <ClientOnlyComponent>
      <footer
        className={`bg-[#141518] text-white w-full space-y-4 flex flex-col items-center`}
      >
        <div
          className={
            "grid grid-cols-1 lg:grid-cols-4 w-full lg:w-5/6 gap-12 px-8 py-8 lg:px-0"
          }
        >
          <div className={"flex flex-col space-y-8 lg:col-span-3"}>
            <div className={"flex flex-col space-y-4"}>
              <h6 className={"font-semibold"}>Available In:</h6>
              <div className="grid w-full md:w-3/4 grid-cols-4 md:grid-cols-7 gap-4 justify-items-center">
                <div className="w-full space-y-2 flex flex-col items-start">
                  <span className="relative w-full h-10">
                    <Image
                      layout={"fill"}
                      objectFit="contain"
                      alt="Digifinex"
                      src="/nigerianew.png"
                    />
                  </span>
                  <p className={"text-xs text-center w-full"}>Nigeria</p>
                </div>
                <div className="w-full space-y-2 flex flex-col items-start">
                  <span className="relative w-full h-10">
                    <Image
                      layout={"fill"}
                      objectFit="contain"
                      alt="Digifinex"
                      src="/kenyanew.png"
                    />
                  </span>
                  <p className={"text-xs text-center w-full"}>Kenya</p>
                </div>

                <div className="w-full space-y-2 flex flex-col items-start">
                  <span className="relative w-full h-10">
                    <Image
                      width={80}
                      height={100}
                      layout={"fill"}
                      objectFit="contain"
                      alt="Digifinex"
                      src="/mexiconew.png"
                    />
                  </span>
                  <p className={"text-xs text-center w-full"}>Mexico</p>
                </div>
                {/* <div className="w-full space-y-2 flex flex-col items-start">
                  <span className="relative w-full h-10">
                    <Image
                      layout={"fill"}
                      objectFit="contain"
                      alt="Digifinex"
                      src="/phillipinesnew.png"
                    />
                  </span>
                  <p className={"text-xs text-center w-full"}>Phillipines</p>
                </div> */}
                {/* <div className="w-full space-y-2 flex flex-col items-start">
                  <span className="relative w-full h-10">
                    <Image
                      layout={"fill"}
                      objectFit="contain"
                      alt="Digifinex"
                      src="/ghananew.png"
                    />
                  </span>
                  <p className={"text-xs text-center w-full"}>Ghana</p>
                </div> */}
              </div>
            </div>

            <div className={"flex flex-col space-y-4"}>
              <div className={"flex flex-col space-y-2"}>
                <p className={`font-semibold text-sm`}>Nigeria Address</p>
                <p className={"text-sm"}>
                  21, Ajanaku Street, Awuse Estate, Off Salvation Road, Opebi,
                  Ikeja Lagos, Nigeria
                </p>
              </div>

              <div className={"flex flex-col space-y-2"}>
                <p className={`font-semibold text-sm`}>Kenya Address</p>
                <p className={"text-sm"}>
                  PineTree Plaza, 7th Floor, Kaburu Drive, Kilimani, P.O. Box
                  59162-00200, Nairobi, Kenya
                </p>
              </div>
            </div>

            <div className={"flex flex-col space-y-2"}>
              <Logo />
              <p className={"text-sm"}>
                DataMynt, Inc. is a financial technology company, not a bank.
              </p>
            </div>

            <ul className={"flex flex-wrap list-none"}>
              <li className={"flex justify-start mr-4"}>
                <Link href="/aboutus">
                  <a className="text-white font-semibold">Team</a>
                </Link>
              </li>
              <li className={"flex justify-start mr-4"}>
                <Link href="/contactus">
                  <a className="text-white font-semibold">Contact</a>
                </Link>
              </li>
              <li className={"flex justify-start mr-4"}>
                <Link href="https://angel.co/company/cryptopayments_/jobs">
                  <a
                    className="text-white font-semibold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Careers
                  </a>
                </Link>
              </li>
              {/* <li className={"flex justify-start mr-4"}>
                <Link href="/press">
                  <a
                    className="text-white font-semibold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Press
                  </a>
                </Link>
              </li>*/}
              <li className={"flex justify-start mr-4"}>
                <Link href="/login">
                  <a
                    className="text-white font-semibold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login
                  </a>
                </Link>
              </li>
              <li className={"flex justify-start mr-4"}>
                <Link href="/terms">
                  <a
                    className="text-white font-semibold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </Link>
              </li>
              <li className={"flex justify-start mr-4"}>
                <Link href="/faq">
                  <a
                    className="text-white font-semibold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    FAQ
                  </a>
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-full flex flex-col space-y-8 justify-between">
            <div className="flex flex-col space-y-4">
              <h6 className={"font-semibold"}>Download from:</h6>

              <div
                className={
                  "relative flex lg:flex-col space-x-2 lg:space-x-0 lg:space-y-4"
                }
              >
                <AppStoreButton
                  grow={isTailwindBreakpoint("md")}
                  type={"light"}
                  store={"apple"}
                ></AppStoreButton>
                <AppStoreButton
                  grow={isTailwindBreakpoint("md")}
                  type={"light"}
                  store={"google"}
                ></AppStoreButton>
              </div>
            </div>

            <div className="flex flex-col space-y-4 w-full">
              <p className={"text-sm"}>Email: sales@datamynt.com</p>

              <ul className={"flex space-x-2 list-none"}>
                <Link href="mailto:sales@datamynt.com">
                  <a
                    className="w-8 md:h-8 flex items-center justify-center text-white bg-[#0051FE] rounded-full p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FiMail />
                  </a>
                </Link>
                <Link href="https://www.linkedin.com/company/datamynt/">
                  <a
                    className="w-8 md:h-8 flex items-center justify-center text-white bg-[#0051FE] rounded-full p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                </Link>
                <Link href="https://twitter.com/DataMynt">
                  <a
                    className="w-8 md:h-8 flex items-center justify-center text-white bg-[#0051FE] rounded-full p-2 mr-8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter />
                  </a>
                </Link>
              </ul>
            </div>
          </div>
        </div>

        <p className="bg-[#0051FE] w-full text-white text-center py-2">
          2022 Data&nbsp;Mynt, Inc. All Rights Reserved.
        </p>
      </footer>
    </ClientOnlyComponent>
  );
};

export default Footer;
