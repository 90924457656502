import { useEffect, useState, useRef } from "react";

const useClickListener = (
  elementId,
  deactivateAlsoOnElementClicked = false
) => {
  const [elementActive, setElementActive] = useState(false);
  const prevElementState = useRef(false);

  useEffect(() => {
    const isElementChild = target => target.closest(`#${elementId}`);

    function closeElement(e) {
      const { target } = e;
      if (!isElementChild(target)) {
        setElementActive(false);
      } else {
        if (deactivateAlsoOnElementClicked) {
          setElementActive(!prevElementState.current ? true : false);
        } else {
          !prevElementState.current && setElementActive(true);
        }
      }
    }

    window.addEventListener("click", closeElement);

    return () => {
      window.removeEventListener("click", elementActive);
    };
  }, [elementId, setElementActive, deactivateAlsoOnElementClicked]);

  useEffect(() => {
    prevElementState.current = elementActive;
  });

  return elementActive;
};

export default useClickListener;
