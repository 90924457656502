import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBarCustomer() {
  return (
    <Menu as="div" className="relative inline-block text-left ">
      <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded-md bg-transparent  py-2 text-sm font-semibold text-BLACK  ">
        Customers
        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span className={"text-white lg:hidden"}>Customers</span>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="lg:absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black ">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-white" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Importers
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-white" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Retail
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-white" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Ecommerce
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-white" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Real Estate
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-white" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Travel
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-white" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Hospitality
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-white" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Gaming
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
