import { createContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

const initialState = {
  articlePage: false
};

const reducer = (state, action) => {
  let newState;
  const callbacks = {
    SET_ARTICLE_PAGE: () => {
      newState = { ...state, articlePage: action.value };
    }
  };

  callbacks[action.type]();

  return newState;
};

const LandingContext = createContext({
  state: initialState,
  dispatch: () => {}
});

const LandingProvider = ({ children, initialStateFromProps }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialStateFromProps || initialState
  );

  const memoizedState = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <LandingContext.Provider value={memoizedState}>
      {children}
    </LandingContext.Provider>
  );
};

LandingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export { LandingContext, LandingProvider };
