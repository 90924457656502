import * as React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import { LandingProvider } from "../../contexts/Landing";

const Container = ({
  children,
  initialStateFromProps,
  onCurrencyChangeNaira,
  onCurrencyShelling
}) => (
  <LandingProvider initialStateFromProps={initialStateFromProps}>
    <div className="containerLayout w-full flex flex-col">
      <Nav
        onCurrencyChangeNaira={onCurrencyChangeNaira}
        onCurrencyChangeShelling={onCurrencyShelling}
      />
      <div className="relative flex-grow">{children}</div>
      <Footer />
    </div>
  </LandingProvider>
);

export default Container;
